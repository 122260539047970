import request from '@/utils/request'

// 查询历史记录列表
export function listHistory(query) {
  return request({
    url: '/valve/valve-history/list',
    method: 'get',
    params: query
  })
}

// 查询历史记录分页
export function pageHistory(query) {
  return request({
    url: '/valve/valve-history/page',
    method: 'get',
    params: query
  })
}

// 查询历史记录详细
export function getHistory(data) {
  return request({
    url: '/valve/valve-history/info',
    method: 'get',
    params: data
  })
}

// 新增历史记录
export function addHistory(data) {
  return request({
    url: '/valve/valve-history/add',
    method: 'post',
    data: data
  })
}

// 修改历史记录
export function updateHistory(data) {
  return request({
    url: '/valve/valve-history/edit',
    method: 'post',
    data: data
  })
}

// 删除历史记录
export function delHistory(data) {
  return request({
    url: '/valve/valve-history/delete',
    method: 'post',
    data: data
  })
}
