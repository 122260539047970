<template>
  <a-drawer width="1400" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ title }}</b>
    </a-divider>
    <a-card :bordered="false" v-if="type == 0">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="菜单名称">
                <a-input v-model="queryParam.name" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="程序命名">
                <a-input v-model="queryParam.key" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6 || 24" :sm="24">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="getInfo(did)"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        size="small"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false">
        <span slot="addr" slot-scope="text, record">
          {{ record.addr.toString() }}
        </span>
      </a-table>
    </a-card>
    <a-card :bordered="false" v-if="type == 1">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="菜单名称">
                <a-input v-model="queryParam.name" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="程序命名">
                <a-input v-model="queryParam.key" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6 || 24" :sm="24">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="getInfo(did)"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 数据展示 -->
      <a-tabs v-model="activeKey">
        <a-tab-pane v-for="(item,key) in list" :key="key" :tab="item.creationtime">
          <a-table
            :loading="loading"
            size="small"
            rowKey="id"
            :columns="columns1"
            :data-source="item.lists"
            :scroll="{ y: 600 }"
            :pagination="false">
            <span slot="addr" slot-scope="text, record">
              {{ record.addr.toString() }}
            </span>
          </a-table>
        </a-tab-pane>
      </a-tabs>
    </a-card>
  </a-drawer>
</template>

<script>
import { getHistory } from '@/api/valve/history'

export default {
  name: 'User',
  props: {
  },
  data() {
    return {
      open: false,
      title: null,
      type: null, // 0操作数据 1运行数据
      info: {},
      loading: false,
      queryParam: {
        name: null,
        key: null
      },
      list: [],
      columns: [
        {
          title: '菜单名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '程序命名',
          dataIndex: 'key',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '寄存器地址',
          dataIndex: 'addr',
          scopedSlots: { customRender: 'addr' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '转换倍数',
          dataIndex: 'beishu',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '写入值',
          dataIndex: 'value',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '转换值',
          dataIndex: 'val',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作时间',
          dataIndex: 'creationtime',
          ellipsis: true,
          align: 'center'
        }
      ],

      activeKey: 0,
      list1: [],
      columns1: [
        {
          title: '菜单名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '程序命名',
          dataIndex: 'key',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '寄存器地址',
          dataIndex: 'addr',
          scopedSlots: { customRender: 'addr' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '转换倍数',
          dataIndex: 'beishu',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '初始数据原始值',
          dataIndex: 'ovalue',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '最新数据原始值',
          dataIndex: 'nvalue',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '初始数据',
          dataIndex: 'oval',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '最新数据',
          dataIndex: 'nval',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '首次读取时间',
          dataIndex: 'screationtime',
          width: 150,
          ellipsis: true,
          align: 'center'
        },
        {
          title: '更新时间',
          dataIndex: 'ecreationtime',
          width: 150,
          ellipsis: true,
          align: 'center'
        }
      ]
    }
  },
  created() {
  },
  methods: {
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        name: null,
        key: null
      }
      this.getInfo(this.did)
    },
    getInfo(id) {
      this.did = id
      this.open = true
      this.loading = true
      const that = this
      getHistory({ id }).then(res => {
        const info = res.data
        this.type = info.type
        this.title = info.name + ' ' + (info.type === 1 ? '运行数据' : '操作数据')
        const list = []
        if (info.type === 0) {
          info.jsonData.map(item => {
            const selectOptions = item.config.selectOptions
            if (selectOptions.length > 0) {
              selectOptions.map(items => {
                if (items.value === item.config.value) {
                  item.config.val = items.text
                }
              })
            } else {
              item.config.value = item.config.value / item.config.beishu
              // item.config.val = item.config.value * item.config.beishu
              item.config.val = that.numMulti(item.config.value, item.config.beishu)
            }
            if (!that.queryParam.name && !that.queryParam.key) {
              list.push({
                creationtime: item.creationtime,
                ...item.config
              })
            } else {
              if (that.queryParam.name && that.queryParam.key) {
                if (item.config.name.indexOf(that.queryParam.name) > -1 && item.config.key.indexOf(that.queryParam.key) > -1) {
                  // console.log(that.queryParam.name, item.config.name, that.queryParam.key, item.config.key, item.config)
                  list.push({
                    creationtime: item.creationtime,
                    ...item.config
                  })
                }
              } else if (that.queryParam.name && item.config.name.indexOf(that.queryParam.name) > -1) {
                // console.log(that.queryParam.name, item.config.name, item.config.key)
                list.push({
                  creationtime: item.creationtime,
                  ...item.config
                })
              } else if (that.queryParam.key && item.config.key.indexOf(that.queryParam.key) > -1) {
                // console.log(that.queryParam.key, item.config.name, item.config.key)
                list.push({
                  creationtime: item.creationtime,
                  ...item.config
                })
              }
            }
          })
        } else if (info.type === 1) {
          info.jsonData.map(item => {
            item.lists = []
            item.list[0].config.map((item2, key2) => {
              if (!that.queryParam.name && !that.queryParam.key) {
                item.lists.push({
                  ecreationtime: item.list[0].creationtime,
                  screationtime: item.list[1].creationtime,
                  name: item2.name,
                  key: item2.key,
                  addr: item2.addr,
                  beishu: item2.beishu,
                  nvalue: item.list[0].config[key2].value / item.list[0].config[key2].beishu,
                  ovalue: item.list[1].config[key2].value / item.list[0].config[key2].beishu,
                  nval: that.setOpt(item.list[0].config[key2].selectOptions, item.list[0].config[key2].value, item.list[0].config[key2].beishu),
                  oval: that.setOpt(item.list[1].config[key2].selectOptions, item.list[1].config[key2].value, item.list[1].config[key2].beishu)
                })
              } else {
                if (that.queryParam.name && that.queryParam.key) {
                  if (item2.name.indexOf(that.queryParam.name) > -1 && item2.key.indexOf(that.queryParam.key) > -1) {
                    // console.log(that.queryParam.name, item2.name, that.queryParam.key, item2.key, item2)
                    item.lists.push({
                      ecreationtime: item.list[0].creationtime,
                      screationtime: item.list[1].creationtime,
                      name: item2.name,
                      key: item2.key,
                      addr: item2.addr,
                      beishu: item2.beishu,
                      nvalue: item.list[0].config[key2].value / item.list[0].config[key2].beishu,
                      ovalue: item.list[1].config[key2].value / item.list[0].config[key2].beishu,
                      nval: that.setOpt(item.list[0].config[key2].selectOptions, item.list[0].config[key2].value, item.list[0].config[key2].beishu),
                      oval: that.setOpt(item.list[1].config[key2].selectOptions, item.list[1].config[key2].value, item.list[1].config[key2].beishu)
                    })
                  }
                } else if (that.queryParam.name && item2.name.indexOf(that.queryParam.name) > -1) {
                  // console.log(that.queryParam.name, item2.name, item2.key)
                  item.lists.push({
                    ecreationtime: item.list[0].creationtime,
                    screationtime: item.list[1].creationtime,
                    name: item2.name,
                    key: item2.key,
                    addr: item2.addr,
                    beishu: item2.beishu,
                    nvalue: item.list[0].config[key2].value / item.list[0].config[key2].beishu,
                    ovalue: item.list[1].config[key2].value / item.list[0].config[key2].beishu,
                    nval: that.setOpt(item.list[0].config[key2].selectOptions, item.list[0].config[key2].value, item.list[0].config[key2].beishu),
                    oval: that.setOpt(item.list[1].config[key2].selectOptions, item.list[1].config[key2].value, item.list[1].config[key2].beishu)
                  })
                } else if (that.queryParam.key && item2.key.indexOf(that.queryParam.key) > -1) {
                  // console.log(that.queryParam.key, item2.name, item2.key)
                  item.lists.push({
                    ecreationtime: item.list[0].creationtime,
                    screationtime: item.list[1].creationtime,
                    name: item2.name,
                    key: item2.key,
                    addr: item2.addr,
                    beishu: item2.beishu,
                    nvalue: item.list[0].config[key2].value / item.list[0].config[key2].beishu,
                    ovalue: item.list[1].config[key2].value / item.list[0].config[key2].beishu,
                    nval: that.setOpt(item.list[0].config[key2].selectOptions, item.list[0].config[key2].value, item.list[0].config[key2].beishu),
                    oval: that.setOpt(item.list[1].config[key2].selectOptions, item.list[1].config[key2].value, item.list[1].config[key2].beishu)
                  })
                }
              }
            })
            list.push(item)
          })
        }
        this.list = list
        this.loading = false
        this.open = true
      })
    },
    setOpt(list, val, beishu) {
      let value = val
      if (list.length > 0) {
        list.map(item => {
          if (item.value === val) {
            value = item.text
          }
        })
      } else {
        // value = val
        // value = val / beishu * beishu
        value = this.numMulti((val / beishu), beishu)
      }
      return value
    },
    // 乘法运算，避免数据相除小数点后产生多位数和计算精度损失。
    numMulti(num1, num2) {
      var baseNum = 0
      try {
        baseNum += num1.toString().split('.')[1].length
      } catch (e) {}
      try {
        baseNum += num2.toString().split('.')[1].length
      } catch (e) {}
      return Number(num1.toString().replace('.', '')) * Number(num2.toString().replace('.', '')) / Math.pow(10, baseNum)
    },
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    }
  }
}
</script>
